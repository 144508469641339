/* eslint-disable */

import React, { useState } from 'react';

import { Switch } from '@chakra-ui/react';

import './styles.css';
import Input from '../../../Form/Input';
import DropDownComponent from '../../../DropDownComponent';
import { redirectTypes } from '../listsDropDown';
import { DataForm } from '../TypeDataForm';

interface CTAFormProps {
  dataForm: DataForm;
  setDataForm: React.Dispatch<React.SetStateAction<DataForm>>;
}

export default function CTAForm({ dataForm, setDataForm }: CTAFormProps) {
  console.log('dataForm: ', dataForm);
  return (
    <div className="container">
      <div className="doubleInputs">
        <Input
          value={dataForm.textoCta}
          name="textoCta"
          label="Texto do CTA"
          onChange={value => {
            setDataForm(state => ({ ...state, textoCta: value.target.value }));
          }}
        />
      </div>

      <div className="doubleInputs">
        <Input
          value={dataForm.redirectValue}
          name="redirectValue"
          label="Valor do redirecionamento"
          onChange={value => {
            setDataForm(state => ({
              ...state,
              redirectValue: value.target.value,
            }));
          }}
        />
        <Input
          value={dataForm.overText}
          name="overText"
          label="Texto acima do CTA"
          onChange={value => {
            setDataForm(state => ({ ...state, overText: value.target.value }));
          }}
        />
      </div>

      <div className="doubleInputs">
        <Input
          value={dataForm.textSize}
          name="textSize"
          label="Tamanho do texto"
          type="number"
          onChange={value => {
            setDataForm(state => ({
              ...state,
              textSize: parseInt(value.target.value),
            }));
          }}
        />
        <Input
          value={dataForm.textSizeOverCTA}
          name="textSizeOverCTA"
          label="Tamanho do texto acima do CTA"
          type="number"
          onChange={value => {
            setDataForm(state => ({
              ...state,
              textSizeOverCTA: parseInt(value.target.value),
            }));
          }}
        />
      </div>

      <div className="doubleInputs">
        <Input
          value={dataForm.justifyText}
          name="justifyText"
          label="Tipo de justificação de texto"
          type="number"
          onChange={value => {
            setDataForm(state => ({
              ...state,
              justifyText: parseInt(value.target.value),
            }));
          }}
        />

        <DropDownComponent
          keyGetValue="typeRedirect"
          valueInitial={dataForm?.typeRedirect?.toString() || ''}
          label="Tipo de redirect"
          options={redirectTypes}
          getValue={setDataForm}
          useParseInt={false}
        />
      </div>
    </div>
  );
}
