import { Select, FormLabel } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

interface ItemList {
  value: string;
  text: string;
}

interface DropDownComponentProps {
  label: string;
  options: ItemList[];
  getValue: any;
  keyGetValue: string;
  valueInitial: string;
  useParseInt?: boolean;
}

export default function DropDownComponent({
  label,
  options,
  getValue,
  valueInitial,
  keyGetValue,
  useParseInt = true,
}: DropDownComponentProps) {
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    const initial = options.find(item => item.value === valueInitial);
    setSelectedValue(initial ? initial.value : '');
  }, [valueInitial, options]);

  return (
    <div style={{ width: '100%' }}>
      <FormLabel>{label}</FormLabel>
      <Select
        value={selectedValue}
        placeholder="Selecione uma opção"
        onChange={event => {
          const newValue = event.target.value;
          setSelectedValue(newValue);
          getValue((state: any) => ({
            ...state,
            [keyGetValue]: useParseInt ? parseInt(newValue, 10) : newValue,
          }));
        }}
      >
        {options.map(item => (
          <option key={item.value} value={item.value}>
            {item.text}
          </option>
        ))}
      </Select>
    </div>
  );
}
