import { DataForm } from './TypeDataForm';

export function verifyIfCanSave(
  data: DataForm,
  imgSavedOnBack: string,
  toast: any,
) {
  if (imgSavedOnBack === '') {
    if (!data.isVideo && data.banner === null) {
      toast({
        title: 'Ops!',
        description: 'Banner obrigatório',
        status: 'error',
        position: 'top-right',
      });
      return false;
    }
  }

  if (data.isVideo && data.urlMidia === '') {
    toast({
      title: 'Ops!',
      description: 'Campo "URL da mídia" obrigatório',
      status: 'error',
      position: 'top-right',
    });
    return false;
  }

  if (data.textoCta === '') {
    toast({
      title: 'Ops!',
      description: 'Campo "Texto do CTA 1" obrigatório',
      status: 'error',
      position: 'top-right',
    });
    return false;
  }

  if (data.redirectValue === '') {
    toast({
      title: 'Ops!',
      description: 'Campo "Valor do redirecionamento 1" obrigatório',
      status: 'error',
      position: 'top-right',
    });
    return false;
  }

  if (data.textoCta2 === '') {
    toast({
      title: 'Ops!',
      description: 'Campo "Texto do CTA 2" obrigatório',
      status: 'error',
      position: 'top-right',
    });
    return false;
  }

  if (data.redirectValue2 === '') {
    toast({
      title: 'Ops!',
      description: 'Campo "Valor do redirecionamento 2" obrigatório',
      status: 'error',
      position: 'top-right',
    });
    return false;
  }

  return true;
}
