/* eslint-disable prettier/prettier */

import React from 'react';
import { Tooltip, Icon, HStack } from '@chakra-ui/react';
import { AiFillInfoCircle, AiOutlineClockCircle } from 'react-icons/ai';
import { isAfter, isBefore, format } from 'date-fns';

interface Props {
  data: any;
}

const Tooltips: React.FC<Props> = ({ data }) => {
  return (
    <HStack spacing={1}>
      <Tooltip label={`${data?.items?.length} itens cadastrados`}>
        <span>
          <Icon w={5} h={5} as={AiFillInfoCircle} color="brand.500" />
        </span>
      </Tooltip>
      {isBefore(new Date(), new Date(data?.schedule_start)) && (
        <Tooltip
          label={`Programado para ${format(
            new Date(data?.schedule_start),
            'dd/MM/yyyy',
          )} às ${format(new Date(data?.schedule_start), 'hh:mm')}`}
        >
          <span>
            <Icon w={5} h={5} as={AiOutlineClockCircle} color="blue.500" />
          </span>
        </Tooltip>
      )}
      {data?.schedule_end && isAfter(new Date(), new Date(data?.schedule_end)) && (
        <Tooltip
          label={`Expirado em ${format(
            new Date(data?.schedule_end),
            'dd/MM/yyyy',
          )} às ${format(new Date(data?.schedule_end), 'hh:mm')}`}
        >
          <span>
            <Icon w={5} h={5} as={AiOutlineClockCircle} color="red.500" />
          </span>
        </Tooltip>
      )}
    </HStack>
  );
};

export default Tooltips;
