/* eslint-disable */

import React, { useLayoutEffect, useState } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import './styles.css';
import CTAForm from './CTAForm/index';
import BannerForm from './BannerForm/index';
import { DataForm } from './TypeDataForm';
import context from '../context';
import { verifyIfCanSave } from './functions';
import {
  CREATE_PAGE_GROUP,
  REMOVE_PAGE_GROUP,
  UPDATE_PAGE_GROUP,
  CREATE_GROUP_ELEMENTS_CTA,
  UPDATE_GROUP_ELEMENT,
} from '../../../graphQL/mutations';
import { GET_PAGE_DATA } from '../../../graphQL/queries';
import sanitizeString from '../../../utils/sanitizeString';

const initialState: DataForm = {
  urlMidia: '',
  overText: '',
  isVideo: false,
  textSize: 10,
  textSizeOverCTA: 15,
  justifyText: 0,
  banner: null,
  textoCta: '',
  redirectValue: '',
  typeRedirect: '',
};

export default function BannerCTA() {
  const toast = useToast();
  const { pageGroup, newPageGroupDTO, onPageGroupCreated, onClose, display } =
    React.useContext(context);
  console.log('pageGroup: ', pageGroup);

  const [createPageGroup, { loading: createLoading, error: createError }] =
    useMutation(CREATE_PAGE_GROUP, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const [
    createElemet,
    { loading: createGroupCtaLoading, error: createGroupCtaError },
  ] = useMutation(CREATE_GROUP_ELEMENTS_CTA, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const [
    updateElemet,
    { loading: updateGroupCtaLoading, error: updateGroupCtaError },
  ] = useMutation(UPDATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const [updatePageGroup, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_PAGE_GROUP, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const [removePageGroup, { loading: deleteLoading, error: deleteError }] =
    useMutation(REMOVE_PAGE_GROUP, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const [dataForm, setDataForm] = useState<DataForm>(initialState);
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const [imgSavedOnBack, setImgSavedOnBack] = useState('');

  function handleSave() {
    const caSave = verifyIfCanSave(dataForm, imgSavedOnBack, toast);

    if (caSave) {
      const payloadCTA: any = {
        ...dataForm,
        file_id: dataForm?.banner?.id || '',
        redirectValue: dataForm.redirectValue,
        textoCta: dataForm.textoCta,
        typeRedirect: dataForm.typeRedirect,
        banner: dataForm.isVideo ? null : dataForm.banner,
        urlMidia: dataForm.isVideo ? dataForm.urlMidia : '',
      };

      delete payloadCTA.banner;

      if (dataForm.isVideo) {
        payloadCTA.file_id = null;
      }

      if (imgSavedOnBack !== '') {
        delete payloadCTA.file_id;
      }

      const sanitizedObj = sanitizeString(payloadCTA);

      const { schedule: scheduleData, ...rest } = sanitizedObj;

      if (rest.urlMidia === null) {
        rest.urlMidia = '';
      }

      try {
        (async () => {
          if (pageGroup) {
            setIsLoad(true);
            await updateElemet({
              variables: {
                updateGroupElementInput: {
                  ...rest,
                  id: pageGroup?.items[0]?.id,
                },
              },
            });
            setIsLoad(false);
            toast({
              title: 'Sucesso!',
              description: 'Grupo editado com sucesso!!',
              status: 'success',
              position: 'top-right',
            });
          } else {
            setIsLoad(true);
            const apiData = await createPageGroup({
              variables: {
                createPageGroupInput: {
                  ...newPageGroupDTO,
                  element_display_id: display?.id,
                },
              },
            })
              .then(async res => {
                if (
                  res?.data?.createPageGroup?.id &&
                  res?.data?.createPageGroup?.display?.element_id
                ) {
                  await createElemet({
                    variables: {
                      createGroupElementInput: {
                        ...rest,
                        page_group_id: res?.data?.createPageGroup?.id,
                        element_id:
                          res?.data?.createPageGroup?.display?.element_id,
                        name: dataForm.textoCta,
                      },
                    },
                  })
                    .then(() => {
                      toast({
                        title: 'Sucesso!',
                        description: 'Item criado com sucesso!!',
                        status: 'success',
                        position: 'top-right',
                      });
                      setIsLoad(false);
                    })
                    .catch(() => {
                      toast({
                        title: 'Erro!',
                        description: 'Erro ao criar o CTA dentro do banner!!',
                        status: 'error',
                        position: 'top-right',
                      });
                      setIsLoad(false);
                    });
                }
              })
              .catch(error => {
                toast({
                  title: 'Erro!',
                  description: error?.message || 'Erro ao inserir itens',
                  status: 'error',
                  position: 'top-right',
                });
                setIsLoad(false);
              });

            if (onPageGroupCreated) onPageGroupCreated(apiData);
            toast({
              title: 'Sucesso!',
              description: 'Grupo criado com sucesso!!',
              status: 'success',
              position: 'top-right',
            });
          }
        })();
      } catch {
        // nada
      } finally {
        onClose();
      }
    }
  }

  const handleDelete = async () => {
    try {
      const confirmed = window.confirm(
        'Tem certeza que deseja excluir o grupo?',
      );
      if (confirmed) {
        setIsLoad(true);
        await removePageGroup({
          variables: {
            id: pageGroup?.id,
          },
        });
        setIsLoad(false);
        toast({
          title: 'Sucess!!',
          description: 'Banner CTA deletado com sucesso!',
          status: 'success',
          position: 'top-right',
        });
        if (onClose) onClose();
      }
    } catch {
      // error
    }
  };

  useLayoutEffect(() => {
    if (pageGroup && pageGroup.items && pageGroup.items.length > 0) {
      setDataForm(obj => ({
        ...obj,
        isVideo: pageGroup.items[0].isVideo,
        urlMidia: pageGroup.items[0].urlMidia,
        overText: pageGroup.items[0].overText,
        textSize: pageGroup.items[0].textSize,
        textSizeOverCTA: pageGroup.items[0].textSizeOverCTA,
        justifyText: pageGroup.items[0].justifyText,
        textoCta: pageGroup.items[0].textoCta,
        redirectValue: pageGroup.items[0].redirectValue,
        typeRedirect: pageGroup.items[0].typeRedirect,
      }));

      setImgSavedOnBack(pageGroup?.items[0]?.file?.url || '');
    }
  }, []);

  /* eslint-disable @typescript-eslint/ban-ts-comment */
  return (
    <Tabs index={tabIndex} onChange={index => setTabIndex(index)}>
      <TabList>
        <Tab>Banner</Tab>
        {/* <Tab isDisabled={dataForm.banner === null}>Dados CTA</Tab> */}
        <Tab>Dados CTA</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <BannerForm
            setIsLoad={setIsLoad}
            setDataForm={setDataForm}
            dataForm={dataForm}
            imgSavedOnBack={imgSavedOnBack}
            setImgSavedOnBack={setImgSavedOnBack}
          />
        </TabPanel>

        <TabPanel>
          <CTAForm dataForm={dataForm} setDataForm={setDataForm} />
        </TabPanel>
      </TabPanels>

      {isLoad && (
        <div className="loadBannerCTA">
          <Spinner color="white" size="lg" />
          <p style={{ color: 'white' }}> Carregando</p>
        </div>
      )}

      <div className="btnsBannerCTA">
        <Button onClick={onClose}>Fechar</Button>
        {!!pageGroup && (
          <Button
            colorScheme="red"
            isLoading={deleteLoading}
            onClick={handleDelete}
          >
            Excluir
          </Button>
        )}
        <Button
          colorScheme="brand"
          isLoading={createLoading || updateLoading}
          onClick={handleSave}
        >
          Salvar
        </Button>
      </div>
    </Tabs>
  );
}
