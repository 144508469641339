import { gql } from '@apollo/client';

export const GET_PAGES = gql`
  query GetPages {
    pages {
      id
      name
      slug
    }
  }
`;

export const GET_ELEMENT_DISPLAYS = gql`
  query GetElementDisplays($element_id: String) {
    elementDisplays(element_id: $element_id) {
      id
      name
      slug
    }
  }
`;

export const GET_PAGE_DATA = gql`
  query GetPageData($slug: String!) {
    elements {
      id
      name
      slug
      displays {
        element_id
        id
        name
        description
        slug
      }
    }
    page(slug: $slug) {
      id
      name
      slug
      groups(workbench: true) {
        id
        element_display_id
        name
        logoColor
        padding
        description
        reference_id
        clicks
        type
        countdown
        schedule_start
        schedule_end
        display {
          slug
        }
        products {
          productId
          productName
          items {
            name
            nameComplete
            images {
              imageUrl
            }
            sellers {
              sellerName
              commertialOffer {
                ListPrice
                Price
                Installments {
                  Value
                  NumberOfInstallments
                  TotalValuePlusInterestRate
                  PaymentSystemName
                  PaymentSystemGroupName
                }
                AvailableQuantity
                IsAvailable
              }
            }
          }
        }
        product {
          productId
          productName
          items {
            name
            nameComplete
            itemId
            images {
              imageUrl
            }
            sellers {
              sellerName
              commertialOffer {
                ListPrice
                Price
                Installments {
                  Value
                  NumberOfInstallments
                  TotalValuePlusInterestRate
                  PaymentSystemName
                  PaymentSystemGroupName
                }
                AvailableQuantity
                IsAvailable
              }
            }
          }
        }
        items(workbench: true) {
          id
          name
          clicks
          type
          countdown
          facets
          reference_value
          redirect_type
          redirect_value
          reference_id
          schedule_start
          schedule_end
          withBorder
          file {
            url
          }
          textoCta
          textoCta2
          redirectValue
          redirectValue2
          typeRedirect
          typeRedirect2
          isVideo
          urlMidia
          textSizeOverCTA
          textSize
          justifyText
          overText
        }
      }
    }
  }
`;

export const GET_GROUP_WITH_ELEMENTS = gql`
  query GetGroupWithElements($group_id: String!) {
    pageGroup(id: $group_id) {
      id
      name
      items {
        id
        name
        textoCta
        textoCta2
        redirect_value
        reference_value
        redirect_type
        isVideo
        file_id
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories(treeLevel: 5, workbench: true) {
      id
      file_id
      name
      reference_id
      active
      url
      Title
      MetaTagDescription
      file {
        url
      }
      children {
        id
        file_id
        name
        reference_id
        active
        url
        Title
        MetaTagDescription
        file {
          url
        }
        children {
          id
          file_id
          name
          reference_id
          active
          url
          Title
          MetaTagDescription
          file {
            url
          }
          children {
            id
            file_id
            name
            reference_id
            active
            url
            Title
            MetaTagDescription
            file {
              url
            }
            children {
              id
              file_id
              name
              reference_id
              active
              url
              Title
              MetaTagDescription
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;
