import React from 'react';

import './styles.css';

const BannerCTADouble: React.FC = ({ data, phoneWidth }: any) => {
  const itemPage = data?.items[0] || null;
  console.log('itemPage double:  ', itemPage);
  return itemPage !== null ? (
    <div className="containerMobileBannerCTADouble">
      {!itemPage?.isVideo ? (
        <img
          src={itemPage?.file?.url}
          alt={itemPage?.textoCta}
          className="imgCtaDouble"
        />
      ) : (
        <div style={{ textAlign: 'center', fontSize: 12 }}>
          <p>
            <b>Url do vídeo CTA:</b> {itemPage?.urlMidia}{' '}
          </p>
          <p>
            <b>Texto CTA1:</b> {itemPage?.textoCta}{' '}
          </p>
          <p>
            <b>Texto CTA2:</b> {itemPage?.textoCta2}{' '}
          </p>
        </div>
      )}
      {!itemPage?.isVideo && (
        <div className="ctaDoubleTextBox">
          <p className="ctaDoubleText">{itemPage?.textoCta}</p>
          <p className="ctaDoubleText">{itemPage?.textoCta2}</p>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default BannerCTADouble;
