/* eslint-disable */

import React, { useState } from 'react';

import { Switch } from '@chakra-ui/react';

import './styles.css';
import Input from '../../../Form/Input';
import DropDownComponent from '../../../DropDownComponent';
import { redirectTypes } from '../listsDropDown';

import { DataForm } from '../TypeDataForm';

interface CTAFormProps {
  dataForm: DataForm;
  setDataForm: React.Dispatch<React.SetStateAction<DataForm>>;
  ctaNumber: number;
}

export default function CTAForm({
  dataForm,
  setDataForm,
  ctaNumber,
}: CTAFormProps) {
  return (
    <div className="container">
      <div className="doubleInputs">
        {ctaNumber === 1 ? (
          <Input
            value={dataForm.textoCta}
            name="textoCta1"
            label="Texto do CTA 1"
            onChange={value => {
              setDataForm(state => ({
                ...state,
                textoCta: value.target.value,
              }));
            }}
          />
        ) : (
          <Input
            value={dataForm.textoCta2}
            name="textoCta2"
            label="Texto do CTA 2"
            onChange={value => {
              setDataForm(state => ({
                ...state,
                textoCta2: value.target.value,
              }));
            }}
          />
        )}

        {ctaNumber === 1 ? (
          <Input
            value={dataForm.redirectValue}
            name="redirectValue"
            label="Valor do redirecionamento 1"
            onChange={value => {
              setDataForm(state => ({
                ...state,
                redirectValue: value.target.value,
              }));
            }}
          />
        ) : (
          <Input
            value={dataForm.redirectValue2}
            name="redirectValue"
            label="Valor do redirecionamento 2"
            onChange={value => {
              setDataForm(state => ({
                ...state,
                redirectValue2: value.target.value,
              }));
            }}
          />
        )}
      </div>

      {ctaNumber === 1 ? (
        <div className="doubleInputs">
          <DropDownComponent
            keyGetValue="typeRedirect"
            valueInitial={dataForm.typeRedirect}
            label="Tipo de redirect 1"
            options={redirectTypes}
            getValue={setDataForm}
            useParseInt={false}
          />
        </div>
      ) : (
        <div className="doubleInputs">
          <DropDownComponent
            keyGetValue="typeRedirect2"
            valueInitial={dataForm.typeRedirect2}
            label="Tipo de redirect 2"
            options={redirectTypes}
            getValue={setDataForm}
            useParseInt={false}
          />
        </div>
      )}
    </div>
  );
}
