import React, { useState } from 'react';
import { useToast, Switch } from '@chakra-ui/react';
import cms from '../../../../apis/cms';
import './styles.css';
import { DataForm } from '../TypeDataForm';
import Input from '../../../Form/Input';

interface BannerFormProps {
  setIsLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setDataForm: React.Dispatch<React.SetStateAction<DataForm>>;
  dataForm: DataForm;
  imgSavedOnBack: string;
  setImgSavedOnBack: React.Dispatch<React.SetStateAction<string>>;
}

export default function BannerForm({
  setIsLoad,
  setDataForm,
  dataForm,
  imgSavedOnBack,
  setImgSavedOnBack,
}: BannerFormProps) {
  const toast = useToast();
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Pega o primeiro arquivo selecionado
    if (!file) {
      toast({
        title: 'Erro!',
        description: 'Insira uma imagem',
        status: 'error',
        position: 'top-right',
      });
      return;
    }

    // Gera uma URL temporária para a pré-visualização
    const previewURL = URL.createObjectURL(file);
    setImagePreview(previewURL);
    setImgSavedOnBack('');

    try {
      const multipart = new FormData();
      multipart.append('file', file);

      setIsLoad(true);
      const { data: uploadData }: any = await cms.rest.post(
        '/upload',
        multipart,
      );
      setIsLoad(false);

      console.log('uploadData: ', uploadData);

      toast({
        title: 'Sucesso!',
        description: 'Imagem enviada com sucesso',
        status: 'success',
        position: 'top-right',
      });
      setDataForm(obj => ({ ...obj, banner: uploadData }));
    } catch (err: any) {
      console.log('err: ', err);

      // Remove a imagem da pré-visualização se a requisição falhar
      setImagePreview(null);

      // Captura a mensagem de erro da API, se disponível
      const errorMessage =
        err.response?.data?.message || 'Erro ao enviar imagem';

      toast({
        title: 'Erro!',
        description: errorMessage,
        status: 'error',
        position: 'top-right',
      });

      setIsLoad(false);
    }
  };

  return (
    <div className="upload-containerBannerCTASingle">
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        <Switch
          isChecked={dataForm.isVideo}
          onChange={e => {
            setDataForm(state => ({ ...state, isVideo: e.target.checked }));
          }}
          className="switchStyle"
        >
          É um video?
        </Switch>

        {dataForm.isVideo && (
          <Input
            value={dataForm.urlMidia}
            name="UrlDaMidia"
            label="URL da mídia"
            onChange={value => {
              setDataForm(state => ({
                ...state,
                urlMidia: value.target.value,
              }));
            }}
          />
        )}
      </div>

      {!dataForm.isVideo && (
        <>
          {imgSavedOnBack === '' && imagePreview && (
            <img src={imagePreview} alt="Preview" className="image-preview" />
          )}

          {imgSavedOnBack === '' && imagePreview === null && (
            <div className="emptyImgCTA">
              <p>Carregue uma imagem para o banner</p>
            </div>
          )}

          {imgSavedOnBack !== '' && (
            <img src={imgSavedOnBack} alt="Preview" className="image-preview" />
          )}

          <label htmlFor="fileInput" className="upload-label">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleUpload}
              className="hidden-input"
            />
            Selecionar Imagem
          </label>
        </>
      )}
    </div>
  );
}
