import { gql } from '@apollo/client';

export const REMOVE_PAGE_GROUP = gql`
  mutation RemovePageGroup($id: String!) {
    removePageGroup(id: $id)
  }
`;

export const CREATE_PAGE_GROUP = gql`
  mutation CreatePageGroup($createPageGroupInput: CreatePageGroupInput!) {
    createPageGroup(createPageGroupInput: $createPageGroupInput) {
      id
      name
      reference_id
      clicks
      countdown
      schedule_start
      schedule_end
      logoColor
      items {
        id
        name
      }
      display {
        element_id
        slug
        name
      }
    }
  }
`;

export const CREATE_GROUP_ELEMENTS_CTA = gql`
  mutation CreateGroupElement(
    $createGroupElementInput: CreateGroupElementInput!
  ) {
    createGroupElement(createGroupElementInput: $createGroupElementInput) {
      id
      name
      textoCta
      textoCta2
      redirectValue
      redirectValue2
      typeRedirect
      typeRedirect2
      isVideo
      urlMidia
      textSizeOverCTA
      textSize
      justifyText
      overText
    }
  }
`;

export const UPDATE_PAGE_GROUP_POSITION = gql`
  mutation UpdatePageGroupPosition(
    $updatePageGroupInput: UpdatePageGroupInput!
  ) {
    updatePageGroup(updatePageGroupInput: $updatePageGroupInput) {
      id
    }
  }
`;

export const UPDATE_PAGE_GROUP = gql`
  mutation UpdatePageGroup($updatePageGroupInput: UpdatePageGroupInput!) {
    updatePageGroup(updatePageGroupInput: $updatePageGroupInput) {
      id
    }
  }
`;

export const CREATE_GROUP_ELEMENT = gql`
  mutation CreateGroupElement(
    $createGroupElementInput: CreateGroupElementInput!
  ) {
    createGroupElement(createGroupElementInput: $createGroupElementInput) {
      id
      name
      redirect_value
      facets
      withBorder
      height
    }
  }
`;

export const UPDATE_GROUP_ELEMENT = gql`
  mutation UpdateGroupElement(
    $updateGroupElementInput: UpdateGroupElementInput!
  ) {
    updateGroupElement(updateGroupElementInput: $updateGroupElementInput) {
      id
      name
      redirect_value
      withBorder
      height
      textoCta
      textoCta2
      redirectValue
      redirectValue2
      typeRedirect
      typeRedirect2
      isVideo
      urlMidia
      textSizeOverCTA
      textSize
      justifyText
      overText
    }
  }
`;

export const REMOVE_GROUP_ELEMENT = gql`
  mutation RemoveGroupElement($id: String!) {
    removeGroupElement(id: $id)
  }
`;

export const UPDATE_GROUP_ELEMENT_POSITION = gql`
  mutation UpdateGroupElementPosition(
    $updateGroupElementInput: UpdateGroupElementInput!
  ) {
    updateGroupElement(updateGroupElementInput: $updateGroupElementInput) {
      id
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput!) {
    updateCategory(updateCategoryInput: $updateCategoryInput) {
      id
    }
  }
`;

export const CREATE_PAGE = gql`
  mutation createPage($createPageInput: CreatePageInput!) {
    createPage(createPageInput: $createPageInput) {
      id
    }
  }
`;

export const REMOVE_PAGE = gql`
  mutation removePage($id: String!) {
    removePage(id: $id)
  }
`;

export const UPDATE_PAGE = gql`
  mutation updatePage($updatePageInput: UpdatePageInput!) {
    updatePage(updatePageInput: $updatePageInput) {
      id
    }
  }
`;

export const CREATE_INTEGRATION = gql`
  mutation createIntegration($createIntegrationInput: CreateIntegrationInput!) {
    createIntegration(createIntegrationInput: $createIntegrationInput) {
      name
      credentials
    }
  }
`;

export const REMOVE_INTEGRATION = gql`
  mutation removeIntegration($id: String!) {
    removeIntegration(id: $id)
  }
`;
