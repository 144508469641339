export const justifyList = [
  {
    value: '0',
    text: 'Centro',
  },
  {
    value: '1',
    text: 'Esquerda',
  },
  {
    value: '2',
    text: 'Direita',
  },
];

export const typesRedirect = [
  {
    value: '0',
    text: 'Teste 0',
  },
  {
    value: '1',
    text: 'Teste 1',
  },
  {
    value: '2',
    text: 'Teste 2',
  },
];

export const redirectTypes = [
  {
    text: 'Produto',
    value: 'product',
  },
  {
    text: 'Categoria',
    value: 'category',
  },
  {
    text: 'Coleção',
    value: 'collection',
  },
  {
    text: 'Coleção Look',
    value: 'collection-look',
  },
  {
    text: 'URL',
    value: 'url',
  },
  {
    text: 'Linx',
    value: 'linx-shelf',
  },
  {
    text: 'Tela dinâmica',
    value: 'app_screen',
  },
  {
    text: 'Webview',
    value: 'webview',
  },
  {
    text: 'Query Vtex',
    value: 'query',
  },
  {
    text: 'Screen on app',
    value: 'screen_on_app',
  },
];
