import React from 'react';

import './styles.css';

const BannerCTA: React.FC = ({ data, phoneWidth }: any) => {
  const itemPage = data?.items[0] || null;
  console.log('itemPage single: ', itemPage);

  return itemPage !== null ? (
    <div className="containerMobileBannerCTASingle">
      {!itemPage?.isVideo ? (
        <img
          src={itemPage?.file?.url}
          alt={itemPage?.textoCta}
          className="imgCtaSingle"
        />
      ) : (
        <div style={{ textAlign: 'center', fontSize: 12 }}>
          <p>
            <b>Url do vídeo CTA:</b> {itemPage?.urlMidia}{' '}
          </p>
          <p>
            <b>Texto CTA:</b> {itemPage?.textoCta}{' '}
          </p>
        </div>
      )}
      {!itemPage?.isVideo && (
        <div className="ctaSingleTextBox">
          {itemPage?.overText !== '' && (
            <p className="ctaSingleText">{itemPage?.overText}</p>
          )}
          <p className="ctaSingleText">{itemPage?.textoCta}</p>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default BannerCTA;
